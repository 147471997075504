import { generateAnswers } from 'components/survey/survey-generator'

import {
  INVESTOR_TYPE,
  PORTAL_PAGE_TYPE,
  ORDER,
  OFFERING_SECTION_BLOCK,
  USER_ROLE,
  PERSONAL_DOCUMENT_TYPES,
  INVESTMENT_DOCUMENT_TYPES,
  OfferingsStatus,
  SUPERVISOR_FOR_ADVISOR,
  SUPERVISOR_ACCESS_KEY,
  SESSION_EXPIRED_TIME_KEY,
  OFFERING_SECTION_DUE_DILIGENCE_BLOCK,
  ConfirmationAccreditationAttemptsStatuses,
  OFFERING_SECTION_SECOND_PAGE,
  OFFERING_SECTION_THIRD_PAGE,
  ReportsDocumentSubscriptionAgreementType,
  ReportsInvestorFilesType,
  QUESTION_TYPE,
  ReportStatuses,
} from 'const/types'
import { storage } from 'utils/storage'

import {
  get,
  post,
  patch,
  remove,
  list,
  put,
  refreshToken,
  postFormData,
  patchFormData,
} from './config-client'
import { authStorage, userStorage, wcodeEnableSavingStorage } from './storage'
import { parseListResults } from './client'

function cache(fn) {
  let cache = {}
  let pending = {}

  return async function (...args) {
    let key = JSON.stringify(args)

    if (cache[key]) return cache[key]

    if (pending[key]) return pending[key]

    let promise = fn.apply(null, args)
    pending[key] = promise

    try {
      let result = await promise
      cache[key] = result
      delete pending[key]
      return result
    } catch (error) {
      delete pending[key]
      delete cache[key]
      throw error
    }
  }
}

function deleteTokenFromCookies() {
  document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

const investor = {
  get: (uuid = required('investor uuid')) =>
    get('investors/' + uuid, { 'groups[]': 'investor:get:no-mask' }),
  supervisorGet: (uuid = required('investor uuid')) =>
    get('investors/' + uuid, { [SUPERVISOR_ACCESS_KEY]: true }),
  getFirst: () => list('users/me/investors', { limit: 1 }).then(({ items }) => items?.[0]),
  count: () => list('users/me/investors', { limit: 0 }).then(({ totalItems }) => totalItems),
  list: (query) => list('users/me/investors', query),
  listWithStatistics: (query) =>
    list('users/me/investors/statistics', {
      order: { lastTransactionDate: ORDER.DESC },
      ...query,
    }),
  create: ({ type, ...data }) =>
    post(
      // type === INVESTOR_TYPE.COMPANY
      //   ? 'investors/company'
      //   : type === INVESTOR_TYPE.TRUST
      //   ? 'investors/trust'
      //   : 'investors/person',
      `investors/${type}`,
      data,
    ),
  update: (uuid = required('investor uuid'), data = {}) => patch(`investors/${uuid}`, data),
  updateFinally: (uuid = required('investor uuid'), data) =>
    patch(`investors/${uuid}/finalize`, data)
      // BACKEND BUG WORKAROUND:
      // investor.type must be person|company, but not the 'accociatedPerson'
      .then(({ type, ...investor }) => ({
        ...investor,
        type: type === 'accociatedPerson' ? 'company' : type,
      })),
  addDocument: (uuid = required('investor uuid'), data) =>
    postFormData(`investors/${uuid}/file`, data),
  removeDocument: (uuid = required('file uuid')) => remove(`investors-file/${uuid}`),
  updateDocument: (uuid = required('file uuid'), data) =>
    postFormData(`investors-file/${uuid}`, data),
  delete: (uuid = required('investor uuid')) => remove(`investor/${uuid}`),

  // sendWisemanRequisites: (data) => post('confirmation-accreditation-attempts', data),
  confirmAccreditationByWiseman: (uuid = required('request uuid'), data) =>
    patch(`confirmation-accreditation-attempts/${uuid}/confirm`, data),
  declineAccreditationByWiseman: (uuid = required('request uuid'), data) =>
    patch(`confirmation-accreditation-attempts/${uuid}/refuse`, data),

  //:TODO redundant ???
  getConfirmationAccreditationAttempt: (uuid = required('attempts uuid')) =>
    get(`confirmation-accreditation-attempts/${uuid}`),
  getConfirmationAccreditationAttemptsByInvestor: (uuid = required('investor uuid'), query) =>
    list(`confirmation-accreditation-attempts/by-investor`, {
      ['investor.uuid']: uuid,
      ...query,
    }),
  getConfirmationAccreditationAttemptsByConfirmatory: (
    uuid = required('user uuid'),
    email = required('user email'),
    query,
  ) =>
    list(`confirmation-accreditation-attempts/by-advisor/${uuid}`, query).then(
      ({ items, ...rest }) => {
        //TODO:Refactor after BE will gives data only for current user
        const updatedItems = items.map((item) => {
          if (item.status === ConfirmationAccreditationAttemptsStatuses.refused) {
            return item
          }
          const currentUserRequest = item?.advisors?.find(
            (request) => request?.email?.toLowerCase() === email?.toLowerCase(),
          )
          if (currentUserRequest?.confirmedAccreditation) {
            const signaturePdf = currentUserRequest?.signaturePdf
            return {
              ...item,
              status: ConfirmationAccreditationAttemptsStatuses.confirmed,
              signaturePdf,
            }
          }

          if (currentUserRequest?.confirmedAccreditation === false) {
            return { ...item, status: ConfirmationAccreditationAttemptsStatuses.refused }
          }

          return item
        })
        return { items: updatedItems, ...rest }
      },
    ),
  getJointSubscription: (token) => get(`investments/joint-signature/${token}`),
  jointSign: (data) => post('investments/joint-signature', data),

  getRegBi: (token) => get(`investments/regbi-signature/${token}`),
  regBiSign: (uuid, data) => post(`investments/${uuid}/regbi-signature`, data),

  getAssociatedSubscription: (token) => get(`investments/associated-signature/${token}`),
  associatedSign: (data) => post('investments/associated-signature', data),

  getIssuerSignInfo: (token) => get(`investments/issuer-signature/${token}`),
  getBrokerDealerInfo: (token) => get(`investments/broker-dealer-signature/${token}`),
  issuerSign: (uuid = required('investment uuid'), data) =>
    post(`investments/${uuid}/additional-form-documents/sign`, {
      type: 'offering_issuer',
      ...data,
    }),
  brokerDealerSign: (uuid = required('investment uuid'), data) =>
    post(`investments/${uuid}/additional-form-documents/sign`, {
      type: 'broker_dealer',
      ...data,
    }),
  signCrsForm: (uuid = required('investment uuid'), data) =>
    post(`investments/${uuid}/additional-form-documents/sign`, {
      type: 'investor',
      formType: 'crs',
      ...data,
    }),

  getConfirmationAccreditationAttemptsByAttempt: (uuid = required('attempt uuid'), query) =>
    list(`confirmation-accreditation-attempt-advisors`, { 'accreditation.uuid': uuid, ...query }),
  sendWisemanRequisites: (uuid = required('attempt uuid'), data) =>
    patch(`confirmation-accreditation-attempts/${uuid}/advisors`, data),
  getConfirmationAttempt: (uuid = required('attempt uuid')) =>
    get(`confirmation-accreditation-attempts/${uuid}`, {}).then((data) => {
      return prepareFromBackConfirmationAttemptAttributes(data)
    }),
  getConfirmationAttempts: (uuid = required('investor uuid'), params) =>
    list('confirmation-accreditation-attempts', { 'investor.uuid': uuid, ...params }),
  getConfirmationAttemptLast: (uuid = required('investor uuid'), params) =>
    get('confirmation-accreditation-attempts/last', { investorUuid: uuid, ...params }).then(
      (data) => {
        return prepareFromBackConfirmationAttemptAttributes(data)
      },
    ),
  getConfirmationAttemptByInvestorAndInvestmentLast: (
    investorUuid = required('investor uuid'),
    investmentUuid = required('investor uuid'),
    params,
  ) =>
    get('confirmation-accreditation-attempts/last', {
      investorUuid,
      investmentUuid,
      ...params,
    }).then((data) => {
      return prepareFromBackConfirmationAttemptAttributes(data)
    }),
  createConfirmationAttempt: (uuid = required('investor uuid'), data) =>
    post('confirmation-accreditation-attempts', { investorUuid: uuid, ...data }),
  updateConfirmationAttemptUpdatedAt: (uuid = required('confirmation attempt uuid')) =>
    patch(`confirmation-accreditation-attempts/${uuid}/refresh-updated-at`, {}),
  updateConfirmationAttemptAttributes: (
    uuid = required('attempt uuid'),
    { investmentUuid, ...data },
  ) =>
    patch(
      `confirmation-accreditation-attempts/${uuid}/attributes`,
      //BE workaround
      {
        ...prepareDataForConfirmationAttemptAttributes(data),
        ...(investmentUuid && { investmentUuid }),
      },
    ).then((data) => {
      return prepareFromBackConfirmationAttemptAttributes(data)
    }),
  confirmationAttemptFileUpload: (uuid = required('attempt uuid'), data) =>
    postFormData(
      `confirmation-accreditation-attempts/${uuid}/documents`,
      //BE workaround
      convertToFileWithAttributes(data),
    ),
  confirmationAttemptFileListByAttempt: (uuid = required('attempt uuid'), query) =>
    list(`confirmation-accreditation-attempt-documents`, {
      'accreditationAttempt.uuid': uuid,
      ...query,
    }),
  removeConfirmationAttemptFile: (uuid = required('document uuid')) =>
    remove(`confirmation-accreditation-attempt-documents/${uuid}`),
}

export const api = {
  investor,

  associatedPerson: {
    listByInvestor: (uuid = required('investor uuid')) =>
      list('associated', {
        ['investor.uuid']: uuid,
        'groups[]': 'investor:get:no-mask',
      }).then(({ items }) => items),
    listByInvestorByInvestment: (
      investorUuid = required('investor uuid'),
      investmentUuid = required('investment uuid'),
    ) =>
      list('associated', {
        ['investor.uuid']: investorUuid,
        groups: ['investor:get:no-mask', 'associated-signatures:get'],
        associatedSignatureInvestmentUuid: investmentUuid,
      }),
    get: (uuid = required('associated person uuid'), params = {}) =>
      get(`associated/${uuid}`, params),
    createByInvestorId: (uuid = required('investor uuid'), data) =>
      post(`investors/${uuid}/associated`, data),
    update: (uuid = required('associated person uuid'), data) => patch(`associated/${uuid}`, data),
    remove: (uuid = required('associated person uuid')) => remove(`associated/${uuid}`),
    signatureSend: (uuid = required('associated person uuid'), data) =>
      patch(`associated/${uuid}/signature-send`, data),
  },
  custodians: {
    list: (query) => list('custodians', query).then(({ items }) => items),
  },

  paymentMethods: {
    getByInvestor: (investorUuid = required('investor uuid')) =>
      get(`investors/${investorUuid}/payment-methods/ach`),
    getByInvestment: (investmentUuid = required('investment uuid'), params = {}) =>
      get(`investments/${investmentUuid}/payment-methods/ach`, params),
    get: (uuid = required('payment method uuid'), params = {}) =>
      get(`payment-methods/${uuid}`, params),
    createAch: (data) => post('payment-methods/ach', data),
    updateAch: (uuid = required('payment method uuid'), data) =>
      patch(`payment-methods/${uuid}/ach`, data),
  },

  investments: {
    get: (uuid = required('investment uuid')) => get(`investments/${uuid}`, {}),
    getWithExtraInfo: (uuid = required('investment uuid'), query) =>
      get(`investments/${uuid}`, {
        ...query,
        groups: [
          'beneficiary:get',
          'investment-custodian:get',
          'reg-bi:get',
          'reg-bi-offering:get',
        ],
      }),
    listByUser: (userUuid, query) => list('investments', { 'user.uuid': userUuid, ...query }),
    listByClient: (clientUuid, query) =>
      list(`advisor/dashboard/investments`, { 'user.uuid': clientUuid, ...query }),
    listByInvestor: (investorUuid, query) =>
      list(`investments`, {
        'investor.uuid': investorUuid,
        groups: [
          'offering:get',
          'investor:get',
          'reg-bi:get',
          'reg-bi-offering:get',
          'investment-custodian:get',
        ],
        ...query,
      }),
    listByInvestorAdvisor: (investorUuid, query) =>
      list(`advisor/dashboard/investments`, {
        'investor.uuid': investorUuid,
        groups: [
          'offering:get',
          'investor:get',
          'reg-bi:get',
          'reg-bi-offering:get',
          'investment-custodian:get',
        ],
        ...query,
      }),
    listClientsInvestmentsNotSigned: (query) =>
      list('advisor/dashboard/investments', {
        groups: [
          'offering:get',
          'investor:get',
          'reg-bi:get',
          'reg-bi-offering:get',
          'investment-custodian:get',
        ],
        ...query,
      }),
    listClientsInvestmentsNotSignedAccountant: (query) =>
      list('accountant/dashboard/investments', {
        groups: [
          'offering:get',
          'investor:get',
          'reg-bi:get',
          'reg-bi-offering:get',
          'investment-custodian:get',
        ],
        ...query,
      }),
    listClientsInvestmentsNotSignedSupervisor: (query) =>
      list('clients/investments', {
        groups: ['offering:get', 'investor:get', 'user:get', 'reg-bi:get', 'reg-bi-offering:get'],
        ...query,
      }),
    create: (data) => post('investments', data),
    update: (uuid = required('investment uuid'), data) => patch(`investments/${uuid}`, data),
    delete: (uuid = required('investment uuid')) => remove(`investments/${uuid}`),
    finalize: (uuid = required('investment uuid'), data) =>
      patch(`investments/${uuid}/finalize`, data),
    sendToClient: (uuid = required('investment uuid')) =>
      patch(`investments/${uuid}/send-to-client`, {}),
    getAgreement: (uuid = required('investment uuid'), params = {}) =>
      get(`investments/${uuid}/subscription-agreement`, params),
    getAdditionalDocumentMetaData: (uuid = required('document uuid')) =>
      get(`additional-form-documents/${uuid}/metadata`),
    getIppForm: (uuid = required('investment uuid'), params = {}) =>
      get(`investments/${uuid}/ipp-agreement`),
    signIppForm: (uuid = required('investment uuid'), data) =>
      patch(`investments/${uuid}/sign-ipp-agreement`, data),
    getAgreementSupervisor: (uuid = required('investment uuid'), params = {}) =>
      get(`investments/${uuid}/subscription-agreement`, {
        [SUPERVISOR_ACCESS_KEY]: true,
        ...params,
      }),
    resendSA: (uuid = required('investment uuid')) => get(`investments/resend-sa-letter/${uuid}`),
    getWire: (investmentUuid = required('investment uuid'), params = {}) =>
      get(`investments/${investmentUuid}/wire-details`, params),
    addAdvisor: (uuid = required('investment uuid'), data) =>
      post(`investments/${uuid}/advisors`, data),
    addAdvisorToTemplates: (uuid = required('investment uuid'), data) =>
      post(`investments/${uuid}/advisor-templates`, data),
    removeAdvisor: (advisorUuid = required('advisor uuid')) =>
      remove(`investment-advisors/${advisorUuid}`),
    removeAdvisorFromTemplate: (advisorUuid = required('advisor uuid')) =>
      remove(`investment-advisor-templates/${advisorUuid}`),
    createCustodian: (investmentUuid = required('investment uuid'), data) =>
      post('investment-custodians', { investmentUuid, ...data }),
    updateCustodian: (custodianUuid = required('custodian uuid'), data) =>
      patch(`investment-custodians/${custodianUuid}`, data),

    //works only for investor
    getBeneficiaryList: (investmentUuid = required('investment uuid')) =>
      list('beneficiaries', { 'investment.uuid': investmentUuid }).then(({ items }) => items),

    getBeneficiary: (uuid = required('beneficiary uuid')) => get(`beneficiaries/${uuid}`),
    addBeneficiary: (investmentUuid = required('investment uuid'), data) =>
      post('beneficiaries', { ...data, investmentUuid }),
    updateBeneficiary: (uuid = required('Beneficiary uuid'), data) =>
      patch(`beneficiaries/${uuid}`, data),
    removeBeneficiary: (uuid = required('Beneficiary uuid')) => remove(`beneficiaries/${uuid}`),
    getDistributionInstructions: (investmentUuid = required('investment uuid'), query) =>
      list(`investment-distribution-instructions`, {
        'investment.uuid': investmentUuid,
        ...query,
      }).then(({ items }) => items?.[0]),
    createDistributionInstructions: (data) => post('investment-distribution-instructions', data),
    updateDistributionInstructions: (uuid = required('Distribution instructions uuid'), data) =>
      patch(`investment-distribution-instructions/${uuid}`, data),
    getSignaturesList: (uuid = required('investment uuid'), query) =>
      list(`investments/${uuid}/signatures-list`, query),
    getSignaturesListWithToken: ({ uuid, token }, params = {}) =>
      list(`investments/${uuid}/signatures-list`, { ...params, token }),
    signatureUpdate: (uuid, data) => patch(`investments/${uuid}/sign`, data),
    regBiUpdate: (uuid, data) => patch(`investments/${uuid}/reg-bi`, data),
    statisticsCache: cache((query) =>
      list('investments/statistics', { withFundingId: true, ...query }).then((response) => {
        const data = response?.items?.[0]
        return {
          totalCommitted: data?.totalCommitted ?? 0,
          totalReceived: data?.totalReceived ?? 0,
        }
      }),
    ),
    statistics: (query) =>
      list('investments/statistics', { withFundingId: true, ...query }).then((response) => {
        const data = response?.items?.[0]
        return {
          totalCommitted: data?.totalCommitted ?? 0,
          totalReceived: data?.totalReceived ?? 0,
        }
      }),
    signExtraFormInvestor: (uuid, data) =>
      post(`investments/${uuid}/additional-form-documents/sign`, { type: 'investor', ...data }),
    signExtraFormJoint: (uuid, data) =>
      post(`investments/${uuid}/additional-form-documents/sign`, {
        type: 'joint_investor',
        ...data,
      }),
    getDocusign: (uuid = required('investment uuid'), anchor = required('anchor type'), token) =>
      get(
        `investments/${uuid}/subscription-agreement/docusign/${anchor}${
          token ? '?token=' + token : ''
        }`,
      ),
  },
  investmentsNotes: {
    list: (uuid = required('investment uuid'), query = {}) =>
      list('trade-review-notes', { ['investment.uuid']: uuid, ...query }),
    create: (uuid = required('investment uuid'), data) =>
      post('trade-review-notes', { investmentUuid: uuid, ...data }),
    get: (uuid = required('note uuid')) => get(`trade-review-notes/${uuid}`),
    edit: (uuid = required('note uuid'), data) => patch(`trade-review-notes/${uuid}`, data),
    delete: (uuid = required('note uuid'), data) =>
      remove(`trade-review-notes/${uuid}`, data).then(parseListResults),
  },
  holdings: {
    listByInvestor: (investorUuid, query) =>
      list(`investors/${investorUuid}/investments`, {
        groups: ['offering:get'],
        ...query,
        limit: 9999,
      }).then(({ items: data }) => {
        const items = Object.values(
          data.reduce((acc, item) => {
            const offering = item?.offering
            if (!offering?.uuid) return acc
            if (!acc[offering?.uuid]) {
              return {
                ...acc,
                [offering.uuid]: {
                  ...offering,
                  items: [item],
                  quantity: item.unitCount ?? 0,
                  averageUnitPrice: item.unitPrice ?? 0,
                },
              }
            }
            if (acc[offering?.uuid]) {
              return {
                ...acc,
                [offering.uuid]: {
                  ...acc[offering?.uuid],
                  items: [...acc[offering?.uuid]?.items, item],
                  quantity: acc[offering.uuid].quantity + item.unitCount ?? 0,
                  averageUnitPrice: (acc[offering.uuid].averageUnitPrice + item.unitPrice ?? 0) / 2,
                },
              }
            }
            return acc
          }, {}),
        )
        return { items, totalItems: items.length }
      }),

    listByUser: (query) =>
      list('users/me/investments', {
        groups: ['offering:get'],
        ...query,
        limit: 9999,
      }).then(({ items: data }) => {
        const items = Object.values(
          data.reduce((acc, item) => {
            const offering = item?.offering
            if (!offering?.uuid) return acc
            if (!acc[offering?.uuid]) {
              return {
                ...acc,
                [offering.uuid]: {
                  ...offering,
                  items: [item],
                  quantity: item.unitCount ?? 0,
                  averageUnitPrice: item.unitPrice ?? 0,
                },
              }
            }
            if (acc[offering?.uuid]) {
              return {
                ...acc,
                [offering.uuid]: {
                  ...acc[offering?.uuid],
                  items: [...acc[offering?.uuid]?.items, item],
                  quantity: acc[offering.uuid].quantity + item.unitCount ?? 0,
                  averageUnitPrice: (acc[offering.uuid].averageUnitPrice + item.unitPrice ?? 0) / 2,
                },
              }
            }
            return acc
          }, {}),
        )
        return { items, totalItems: items.length }
      }),

    listByClient: (clientUuid = required('client uuid'), query) =>
      list(`users/${clientUuid}/investments`, {
        groups: ['offering:get'],
        ...query,
        limit: 9999,
      }).then(({ items: data }) => {
        const items = Object.values(
          data.reduce((acc, item) => {
            const offering = item?.offering
            if (!offering?.uuid) return acc
            if (!acc[offering?.uuid]) {
              return {
                ...acc,
                [offering.uuid]: {
                  ...offering,
                  items: [item],
                  quantity: item.unitCount ?? 0,
                  averageUnitPrice: item.unitPrice ?? 0,
                },
              }
            }
            if (acc[offering?.uuid]) {
              return {
                ...acc,
                [offering.uuid]: {
                  ...acc[offering?.uuid],
                  items: [...acc[offering?.uuid]?.items, item],
                  quantity: acc[offering.uuid].quantity + item.unitCount ?? 0,
                  averageUnitPrice: (acc[offering.uuid].averageUnitPrice + item.unitPrice ?? 0) / 2,
                },
              }
            }
            return acc
          }, {}),
        )
        return { items, totalItems: items.length }
      }),
  },
  survey: {
    getGlobalByUser: (uuid = required('user uuid')) =>
      get(`surveys/global/user/${uuid}`).then(({ questions, answers }) => ({
        questions: questions?.map(initQuestion).sort(questionsSorter),
        answers,
      })),
    updateGlobalByUser: (uuid = required('user uuid'), data) =>
      put(`surveys/global/user/${uuid}`, data).then(({ questions, answers }) => ({
        questions,
        answers,
      })),
    //Redundant
    getGlobalByInvestor: (uuid = required('investor uuid')) =>
      get(`surveys/global/investor/${uuid}`).then(({ questions, answers }) => ({
        questions: questions?.map(initQuestion).sort(questionsSorter),
        answers,
      })),
    //Redundant
    updateGlobalByInvestor: (uuid = required('investor uuid'), data) =>
      put(`surveys/global/investor/${uuid}`, data).then(({ questions, answers }) => ({
        questions,
        answers,
      })),
    getGlobalByInvestment: (uuid = required('investment uuid')) =>
      get(`surveys/global/investment/${uuid}`).then(({ questions, answers }) => ({
        questions: questions?.map(initQuestion).sort(questionsSorter),
        answers,
      })),
    getGlobalByInvestmentSupervisor: (uuid = required('investment uuid')) =>
      get(`surveys/global/investment/${uuid}`, { [SUPERVISOR_ACCESS_KEY]: true }).then(
        ({ questions, answers }) => ({
          questions: questions?.map(initQuestion).sort(questionsSorter),
          answers,
        }),
      ),
    updateGlobalByInvestment: (uuid = required('investment uuid'), data) =>
      put(`surveys/global/investment/${uuid}`, data).then(
        ({ questions, object: investment, answers }) => ({
          questions,
          investment,
          answers,
        }),
      ),
    getCustomByInvestor: (uuid = required('investor uuid')) =>
      get(`surveys/custom/investor/${uuid}`).then(({ questions, answers }) => ({
        questions: questions?.map(initQuestion).sort(questionsSorter),
        answers,
      })),
    updateCustomByInvestor: (uuid = required('investor uuid'), data) =>
      put(`surveys/custom/investor/${uuid}`, data).then(({ questions, answers }) => ({
        questions,
        answers,
      })),
    getCustomByInvestment: (uuid = required('investment uuid'), isInvestorOwner) =>
      get(`surveys/custom/investment/${uuid}`).then(({ questions, answers }) => ({
        questions: questions
          ?.map((question) => initQuestion(question, isInvestorOwner))
          .sort(questionsSorter),
        answers,
      })),
    updateCustomByInvestment: (uuid = required('investment uuid'), data) =>
      put(`surveys/custom/investment/${uuid}`, data).then(
        ({ questions, object: investment, answers }) => ({
          questions,
          investment,
          answers,
        }),
      ),
    getAdditionalCustomByInvestment: (uuid = required('investment uuid'), isInvestorOwner) =>
      get(`surveys/additional-custom/investment/${uuid}`).then(({ questions, answers }) => ({
        questions: questions
          ?.map((question) => initQuestion(question, isInvestorOwner))
          .sort(questionsSorter),
        answers,
      })),
    updateAdditionalCustomByInvestment: (uuid = required('investment uuid'), data) =>
      put(`surveys/additional-custom/investment/${uuid}`, data).then(
        ({ questions, object: investment, answers }) => ({
          questions,
          investment,
          answers,
        }),
      ),

    getAdditionalCustomSecondByInvestment: (uuid = required('investment uuid'), isInvestorOwner) =>
      get(`surveys/additional-custom-2/investment/${uuid}`).then(({ questions, answers }) => ({
        questions: questions
          ?.map((question) => initQuestion(question, isInvestorOwner))
          .sort(questionsSorter),
        answers,
      })),
    updateAdditionalCustomSecondByInvestment: (uuid = required('investment uuid'), data) =>
      put(`surveys/additional-custom-2/investment/${uuid}`, data).then(
        ({ questions, object: investment, answers }) => ({
          questions,
          investment,
          answers,
        }),
      ),
    getSurveyTest: (uuid = required('survey uuid'), userUuid = required('user uuid'), data) =>
      get(`surveys/${uuid}/user/${userUuid}`, data),
    updateSurveyTest: (uuid = required('survey uuid'), userUuid = required('user uuid'), data) =>
      put(`surveys/${uuid}/user/${userUuid}`, data),
    shareSurveyTest: (data) => post('due-diligence-test-send-email', data), // data = { offeringName, offeringUuid, advisorName, issuer, url, email }
  },
  signatureTemplateItem: {
    get: (uuid = required('signature uuid')) => get(`signature-template-items/${uuid}`),
    list: (query) => list('signature-template-items', query),
    create: (data) => post('signature-template-items', data),
    update: (uuid = required('signature uuid'), data) =>
      patch(`signature-template-items/${uuid}`, data),
    remove: (uuid = required('signature uuid')) => remove(`signature-template-items/${uuid}`),
    listByOffering: (uuid = required('offering uuid'), query) =>
      list('signature-template-items', {
        appObjectType: 'offering',
        appObjectUuid: uuid,
        ...query,
      }),
    listByInvestment: (uuid = required('offering uuid'), query) =>
      list('signature-template-items', {
        appObjectType: 'investment',
        appObjectUuid: uuid,
        ...query,
      }),
    listByInvestmentExtended: (uuid = required('offering uuid'), query) =>
      list(`investments/${uuid}/signatures`, query),
    getSignatureRequest: (uuid = required('signature uuid')) => get(`signature-requests/${uuid}`),
  },

  register: {
    createUser: (data) =>
      post('users/register', data).then(({ auth, user }) => {
        const limitedAdminOrLicensee = user.roles.find(
          (role) => role === USER_ROLE.LIMITED_ADMIN || role === USER_ROLE.PLATFORM_LICENSEE,
        )
        if (limitedAdminOrLicensee) {
          return { auth, user }
        }
        wcodeEnableSavingStorage.value = true
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
    emailConfirm: (data) =>
      post('users/register/confirm', data).then(({ auth, user }) => {
        wcodeEnableSavingStorage.value = true
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
    emailConfirmResend: () => post('users/register/confirm/send', {}),
    updateUserInfo: (data) => put('public/auth/registration', data),
  },

  auth: {
    login: (data) =>
      post('users/login', data).then(({ auth, user }) => {
        if (auth?.full) {
          storage.write(SESSION_EXPIRED_TIME_KEY)
          wcodeEnableSavingStorage.value = true
          authStorage.auth = auth
          userStorage.value = user
        }
        return { auth, user }
      }),
    verificationMFA: (data) =>
      post('users/mfa/verify', data).then(({ auth, user }) => {
        storage.write(SESSION_EXPIRED_TIME_KEY)
        wcodeEnableSavingStorage.value = true
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
    verificationMFAResend: (data) => post('users/mfa/request-code', data),
    refreshToken: refreshToken,
    recoverPassword: bind(post, 'users/reset-password/request'),
    recoverPasswordSet: (data) =>
      post('users/reset-password/update', data).then(({ auth, user }) => {
        wcodeEnableSavingStorage.value = true
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
    userSetPassword: (data) =>
      post('users/set-password', data).then(({ auth, user }) => {
        wcodeEnableSavingStorage.value = true
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
    logout: () => {
      post('users/logout', {
        accessToken: authStorage.accessToken,
        refreshToken: authStorage.refreshToken,
      }).finally(() => {
        //if something will change here update it in http-client unauthorizedLogOut
        wcodeEnableSavingStorage.value = true
        authStorage.auth = null
        deleteTokenFromCookies()
      })
    },
  },

  user: {
    getAccount: () =>
      get(`users/me`, {}).then((user) => {
        userStorage.value = user
        return user
      }),
    updateAccount: (data) =>
      patch(`users/me`, data).then((user) => {
        userStorage.value = user
        return user
      }),
    updateAdvisorPartial: (uuid = required('client uuid'), data = {}) =>
      patch(`users/${uuid}/partial`, data).then((user) => {
        userStorage.value = user
        return user
      }),
    changePassword: (data) => patch(`users/me/password`, data),
    signCrsForm: ({ signature, uuid }) =>
      post(`users/${uuid}/additional-form-documents/sign`, { type: 'user', signature: signature }),
    userNotes: {
      list: (uuid = required('client uuid'), query = {}) =>
        list('user-notes', { ['user.uuid']: uuid, ...query }),
      create: (uuid = required('client uuid'), data) =>
        post('user-notes', { userUuid: uuid, ...data }),
      get: (uuid = required('note uuid')) => get(`user-notes/${uuid}`),
      edit: (uuid = required('note uuid'), data) => patch(`user-notes/${uuid}`, data),
      delete: (uuid = required('note uuid'), data) =>
        remove(`user-notes/${uuid}`, data).then(parseListResults),
    },
  },

  countries: {
    list: ({ limit = 300, page = 1 } = {}) => list('countries', { limit, page }),
  },

  states: {
    list: ({ limit = 100, page = 1 } = {}) => list('states', { limit, page }),
  },

  offering: {
    get: (uuid = required('offering uuid'), params) => get(`offerings/${uuid}`, params),
    getWithCustomFields: (uuid = required('offering uuid'), params) =>
      get(`offerings/${uuid}`, {
        ...params,
        groups: ['offering-custom-field:get', 'reg-bi-offering:get'],
      }),
    getFA: (uuid = required('offering uuid'), params) =>
      get(`offerings/${uuid}/fundamerica`, params),
    list: (query) =>
      list(`offerings`, {
        ...query,
        enabled: true,
      }),
    listClosed: (query) =>
      list(`offerings`, { ...query, status: [OfferingsStatus.closed], enabled: true }),
    listByOwner: ({ userUuid = required('user uuid'), ...query }) =>
      list(`offerings`, { ...query, ['offering_licensees_user_uuid']: userUuid }),
    listByPortals: ({ portalsUuid = required('array of portals uuid'), ...query }) =>
      list(`offerings`, { ...query, admin: true, ['portals.uuid']: portalsUuid }),
    getOfferingOnboarding: (uuid = required('offering uuid'), params) =>
      get(`offerings/${uuid}`, {
        ...params,
        groups: [
          'offering-issuer:get',
          'offering-security:get',
          'offering-information:get',
          'subscription-agreement-template:get',
          'offering-associated-person:get',
          'file:get',
        ],
      }),
    update: (data, uuid = required('offering uuid')) => patch(`offerings/${uuid}`, data),
    createDueDiligencePdf: (data) => post('generate-pdf-from-html', data),
    dueDiligenceTestImport: (data) => postFormData('due-diligence-test-import', data),
  },

  offeringSections: {
    get: (uuid = required('section uuid')) => get(`offering-sections/${uuid}`),
    list: (query) => list(`offering-sections`, query),
    listByOffering: (
      offeringUuid = required('offering uuid'),
      { block = Object.values(OFFERING_SECTION_BLOCK), ...query } = {},
    ) =>
      list(`offering-sections`, {
        ['offering.uuid']: offeringUuid,
        order: { weight: ORDER.ASC },
        enabled: true,
        block,
        ...query,
      }),
    addReview: (uuid) => patch(`offering-sections/${uuid}/add-reviewed-by`, {}),
    subscriptionAgreementVariables: () =>
      list('subscription-agreement-variable-groups', {
        groups: ['subscription-agreement-variable:get'],
      }),
    corruptPdf: (uuid = required('section uuid')) =>
      patch(`offering-sections/${uuid}/corrupt-pdf`, {}),
  },

  offeringIssuer: {
    create: (data, offeringUuid = required('offering uuid')) =>
      post('offering-issuers', { offeringUuid, ...data }),
    update: (data, issuerUuid = required('issuer uuid')) =>
      patch(`offering-issuers/${issuerUuid}`, data),
  },
  offeringAssociatedPerson: {
    list: (query) => list('offering-associated-people', query).then(({ items }) => items),
    listByOffering: (offeringUuid = required('offering uuid'), query) =>
      list('offering-associated-people', { ['offering.uuid']: offeringUuid, ...query }).then(
        ({ items }) => items,
      ),
    create: (data) => post('offering-associated-people', data),
    get: (uuid = required('person uuid'), params) =>
      get(`offering-associated-people/${uuid}`, params),
    update: (uuid = required('person uuid'), data) =>
      patch(`offering-associated-people/${uuid}`, data),
    remove: (uuid = required('person uuid')) => remove(`offering-associated-people/${uuid}`),
  },

  offeringSecurity: {
    list: (query) => list('offering-securities', query),
    create: (data, offeringUuid = required('offering uuid')) =>
      post('offering-securities', { offeringUuid, ...data }),
    get: (uuid = required('offering security uuid'), params) =>
      get(`offering-securities/${uuid}`, params),
    update: (data, uuid = required('offering security uuid')) =>
      patch(`offering-securities/${uuid}`, data),
    remove: (uuid = required('offering security uuid')) => remove(`offering-securities/${uuid}`),
  },

  offeringInformation: {
    create: (data, offeringUuid = required('offering uuid')) =>
      post('offering-informations', { offeringUuid, ...data }),
    update: (data, issuerUuid = required('issuer uuid')) =>
      patch(`offering-informations/${issuerUuid}`, data),
  },

  offeringSubscriptionAgreementTemplate: {
    create: (data, offeringUuid = required('offering uuid')) =>
      postFormData(
        'subscription-agreement-templates',
        { offeringUuid, ...data },
        {
          'Content-Type': 'multipart/form-data',
        },
      ),
    update: (data, subscriptionUuid = required('subscription uuid')) =>
      patch(`subscription-agreement-templates/${subscriptionUuid}`, data),
  },

  offeringSectionAccessRequest: {
    findBySection: (sectionUuid = required('section uuid'), query = {}) =>
      list(`access-requests`, {
        ['objectUuid']: sectionUuid,
        order: { weight: ORDER.ASC },
        enabled: true,
        ...query,
      }).then(({ items }) => items?.[0] ?? null),
  },

  offeringDocuments: {
    listByOffering: (offeringUuid = required('offering uuid'), query = {}) =>
      list(`offerings/${offeringUuid}/offering-documents`, {
        ...query,
        offeringSectionNotBlocks: [
          OFFERING_SECTION_BLOCK.DATA_ROOM,
          OFFERING_SECTION_DUE_DILIGENCE_BLOCK.DUE_DILIGENCE_DATA_ROOM,
          OFFERING_SECTION_SECOND_PAGE.SECOND_PAGE_DATA_ROOM,
          OFFERING_SECTION_THIRD_PAGE.THIRD_PAGE_DATA_ROOM,
        ],
      }).catch((error) => {
        if (error.unauthorized) return { items: null }
        throw error
      }),
    listByOfferingSection: (sectionUuid = required('section uuid'), query = {}) =>
      list(`offering-documents`, {
        ...query,
        'offeringSection.uuid': sectionUuid,
      }).catch((error) => {
        if (error.unauthorized) return { items: null }
        throw error
      }),
  },

  offeringsUpdates: {
    postList: (query) => list('offering-updates/posts', { ...query, groups: ['offering:get'] }),
    postListByClient: ({ clientUuid = required('client uuid'), ...query } = {}) =>
      list('offering-updates/posts', {
        ...query,
        groups: ['offering:get'],
        'user.uuid': clientUuid,
      }),
    postListByInvestor: ({ investorUuid = required('client uuid'), ...query } = {}) =>
      list('offering-updates/posts', {
        ...query,
        groups: ['offering:get'],
        'investor.uuid': investorUuid,
      }),
  },

  unitClass: {
    get: (uuid = required('unit class uuid')) => get(`unit-class/${uuid}`),
    create: (data) => post(`unit-class`, data),
    update: (uuid = required('unit class uuid'), data) => patch(`unit-class/${uuid}`, data),
    remove: (uuid = required('unit class uuid')) => remove(`unit-class/${uuid}`),
    list: (query) => list('unit-class', query),
    listByOffering: (uuid = required('offering uuid'), query) =>
      list('unit-class', {
        'offering.uuid': uuid,
        ...query,
      }),
  },

  banks: {
    getInfo: (routingNumber = required('routingNumber')) => get(`bank-info/${routingNumber}`, {}),
  },

  portal: {
    get: () =>
      get('portals/current')
        // WORKAROUND - disabled user still have see at least something
        .catch(() => ({})),
    list: () => list('portals'),
  },

  portalPages: {
    get: (exactUrl = required('page url')) =>
      list(`portal-pages`, { exactUrl, limit: 1, groups: ['content:get'] }).then(({ items }) => {
        if (!items?.[0]) {
          throw { error: 'Page not found' }
        }
        return items[0]
      }),
    list: (query) =>
      list('portals/current/portal-pages', { ...query, type: PORTAL_PAGE_TYPE.PAGE })
        // WORKAROUND - disabled user still have see at least something
        .catch(() => ({
          items: [],
          totalItems: 0,
        })),
    landingArticlesList: (query) =>
      list('portals/current/portal-pages', { ...query, type: PORTAL_PAGE_TYPE.LANDING_BLOCK })
        // WORKAROUND - disabled user still have see at least something
        .catch(() => ({
          items: [],
          totalItems: 0,
        })),
  },

  advisors: {
    get: (query) => list('advisors', query),
  },

  clients: {
    get: (uuid) => get(`users/${uuid}`),
    create: (data) => post('users/register', data),
    update: (uuid = required('client uuid'), data = {}) => patch(`users/${uuid}`, data),
    list: (query) => list('users', { ...query, roles: USER_ROLE.INVESTOR }),
    listWithStatistics: (query) =>
      list('users/statistics', {
        order: { updatedAt: ORDER.DESC },
        ...query,
        roles: USER_ROLE.INVESTOR,
      }),

    investorsList: (uuid, query) =>
      list(`users/${uuid}/investors/statistics`, { order: { updatedAt: ORDER.DESC }, ...query }),

    singleLogin: (confirmationToken) =>
      post('users/client-login', { confirmationToken }).then(({ auth, user }) => {
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
    addAdvisor: (uuid = required('user uuid'), data) => post(`users/${uuid}/advisors`, data),
    getAdvisors: (uuid = required('user uuid'), query) => list(`users/${uuid}/advisors`, query),
    getFirstAdvisor: (uuid = required('user uuid'), query) =>
      list(`users/${uuid}/advisors`, query).then(({ items }) => items?.[0]),
    removeAdvisor: (userUuid, advisorId) => remove(`users/${userUuid}/advisors/${advisorId}`),
    clientActivity: (uuid = required('uuid'), query) =>
      list('user-activities', { 'user.uuid': uuid, ...query }),
  },

  csa: {
    listByAdvisor: (query) => list('csa/by-advisor', query),
    advisorsList: (query) => list(`csa/by-csa`, query),
    listByAdvisorWithUuid: (advisorUuid = required('advisor uuid'), query) =>
      list('csa/by-advisor', { ...query, advisorUuid }),
    advisorsListWithUuid: (csaUuid = required('csa uuid'), query) =>
      list(`csa/by-csa`, { ...query, csaUuid }),
    approve: (uuid) => patch(`csa/access/${uuid}/approve`),
    deny: (uuid) => patch(`csa/access/${uuid}/deny`),
    addAdvisor: (data) => post(`users/csa/me/advisors`, data),
    addAdvisorByUser: (uuid = required('user uuid'), data) =>
      post(`users/csa/${uuid}/advisors`, data),
    addAdvisorRequest: (uuid = required('user uuid')) =>
      post(`csa/impersonate/mfa`, { user: uuid }),
    addAdvisorCodeApprove: (uuid = required('user uuid'), mfaCode) =>
      patch(`dashboard/users/${uuid}/impersonate`, { mfaCode }).then(({ auth, user }) => {
        storage.write(SESSION_EXPIRED_TIME_KEY)
        wcodeEnableSavingStorage.value = true
        authStorage.auth = auth
        userStorage.value = user
        return { auth, user }
      }),
  },

  supervisor: {
    update: (uuid, data) => patch(`users/${uuid}`, data),
    // supervisorsList: (query) => list('users', { filter_roles: SUPERVISOR_FOR_ADVISOR }),
    // supervisorsList: (query) =>
    //   list('users', {
    //     ...query,
    //     roles: USER_ROLE.SUPERVISOR,
    //   }),
    // advisorsList: (query) => {
    //   return new Promise((resolve) =>
    //     setTimeout(resolve, 500, { items: [{ name: 'advisor', uuid: 'test' }], totalItems: 5 }),
    //   )
    // },
    advisorsList: ({ uuid, ...query }) =>
      list(`users/${uuid}/advisors`, { ...query, [SUPERVISOR_ACCESS_KEY]: true }),
    // advisorGet: (uuid) => {
    //   return new Promise((resolve) => setTimeout(resolve, 500, { name: 'advisor', uuid: 'test' }))
    // },
    advisorGet: (uuid = required('advisor uuid')) => get(`users/${uuid}`, {}),
    // advisorClientsListWithStatistics: ({ uuid, ...query }) => {
    //   return new Promise((resolve) =>
    //     setTimeout(resolve, 500, { items: [{ name: 'client', uuid: 'test' }], totalItems: 5 }),
    //   )
    // },
    advisorClientsListWithStatistics: ({ advisorUuid, ...query }) =>
      list('users/statistics', {
        order: { updatedAt: ORDER.DESC },
        ...query,
        advisor_uuid: advisorUuid,
        roles: USER_ROLE.INVESTOR,
        [SUPERVISOR_ACCESS_KEY]: true,
      }),
    advisorClientGet: (uuid = required('client uuid')) => get(`users/${uuid}`, {}),
    advisorClientsInvestorsList: (uuid, query) =>
      list(`users/${uuid}/investors/statistics`, {
        order: { updatedAt: ORDER.DESC },
        [SUPERVISOR_ACCESS_KEY]: true,
        ...query,
      }),
  },

  AccessRequests: {
    list: (query) => get('access-requests', query),
    post: (data) => post('access-requests', data),
  },

  notifications: {
    list: (query) => list('notifications', query),
    markNotificationViewed: (uuid) => patch(`notifications/${uuid}`, { status: 'viewed' }),
  },

  documents: {
    listPersonal: (query = {}) =>
      list(`investor-files`, {
        ...query,
        mine: true,
        type: PERSONAL_DOCUMENT_TYPES,
        groups: ['investor:get'],
      }),
    listPersonalByInvestor: ({ investorUuid = required('investor uuid'), ...query } = {}) =>
      list(`investor-files`, {
        type: PERSONAL_DOCUMENT_TYPES,
        ...query,
        'investor.uuid': investorUuid,
      }),
    listClientPersonalByInvestor: ({ investorUuid = required('investor uuid'), ...query } = {}) =>
      list(`advisor/dashboard/investor-files`, {
        type: PERSONAL_DOCUMENT_TYPES,
        ...query,
        'investor.uuid': investorUuid,
        [SUPERVISOR_ACCESS_KEY]: true,
      }),
    listPersonalByClient: ({ clientUuid = required('client uuid'), ...query } = {}) =>
      list(`advisor/dashboard/investor-files`, {
        type: PERSONAL_DOCUMENT_TYPES,
        ...query,
        'investor.user.uuid': clientUuid,
        groups: ['investor:get'],
        [SUPERVISOR_ACCESS_KEY]: true,
      }),
    listInvestment: (query = {}) =>
      list(`investor-files`, {
        type: INVESTMENT_DOCUMENT_TYPES,
        ...query,
        mine: true,
        groups: ['investor:get', 'offering:get'],
      }),
    listInvestmentByClient: ({ clientUuid = required('client uuid'), ...query } = {}) =>
      list(`advisor/dashboard/investor-files`, {
        type: INVESTMENT_DOCUMENT_TYPES,
        ...query,
        'investor.user.uuid': clientUuid,
        groups: ['investor:get', 'offering:get'],
      }),
    listInvestmentByInvestor: ({ investorUuid = required('investor uuid'), ...query } = {}) =>
      list(`investor-files`, {
        type: INVESTMENT_DOCUMENT_TYPES,
        ...query,
        'investor.uuid': investorUuid,
        groups: ['offering:get'],
      }),
    listInvestmentByInvestorAdvisor: ({
      investorUuid = required('investor uuid'),
      ...query
    } = {}) =>
      list(`advisor/dashboard/investor-files`, {
        type: INVESTMENT_DOCUMENT_TYPES,
        ...query,
        'investor.uuid': investorUuid,
        groups: ['offering:get'],
      }),
    listInvestmentByAdvisor: (query = {}) =>
      list(`advisor/dashboard/investor-files`, {
        type: INVESTMENT_DOCUMENT_TYPES,
        ...query,
        groups: ['investor:get', 'offering:get'],
      }),
    listInvestmentByAccountant: (query = {}) =>
      list(`accountant/dashboard/investor-files`, {
        ...query,
        type: INVESTMENT_DOCUMENT_TYPES,
        groups: ['investor:get', 'offering:get'],
      }),
    listOfferingUpdates: (query = {}) =>
      list(`offering-updates/files`, {
        ...query,
        groups: ['offering:get', 'offering-updates:files:get'],
      }),

    listOfferingUpdatesByClient: ({ clientUuid = required('client uuid'), ...query } = {}) =>
      list(`offering-updates/files`, {
        ...query,
        'user.uuid': clientUuid,
        groups: ['offering:get', 'offering-updates:files:get'],
      }),

    listOfferingUpdatesByInvestor: ({ investorUuid = required('investor uuid'), ...query } = {}) =>
      list(`offering-updates/files`, {
        ...query,
        'investor.uuid': investorUuid,
        groups: ['offering:get', 'offering-updates:files:get'],
      }),
  },
  userActivity: {
    post: (data) => post('user-activities', data),
    reportsList: (query) => list('dashboard/reports', { reportType: 'user_activity', ...query }),
  },
  wcodes: {
    send: (data) => post(`wcodes/investors`, data),
  },
  reports: {
    initReportGeneration: (reportType, { entityType, ...query }) =>
      get(`${reportType}-report/${entityType}`, query),
    getReportStatus: (uuid = required('uuid')) => get(`reports/${uuid}`),
    reportsList: (query) => list('reports', query),
  },
  image: {
    upload: (data) => postFormData('files', data),
  },
  errors: {
    send: (data) => post('errors', data),
  },
  savedColumns: {
    list: (query) => list('saved-columns', query),
    getByGroup: (group) =>
      list('saved-columns', { group: group }).then(({ items }) => items?.map(({ name }) => name)),
    post: (data) => post('saved-columns', { data: [data] }),
  },

  documentsShare: {
    // { emails:[email1, email2], investorFileUuids: [uuid1, uuid2]}
    post: (data) => {
      if (!Array.isArray(data?.investorFileUuids)) {
        throw { error: `Investors file uuids must be an array` }
      }
      if (!Array.isArray(data?.emails)) {
        throw { error: `Emails must be an array` }
      }
      return post('investor-files/share', data)
    },
    removeShare: (uuid = required('activity uuid'), query) =>
      remove(`shared-investor-files/${uuid}`, query),
    getListSharedUsersByFile: (uuid = required('file uuid'), query) =>
      list(`investor-files/${uuid}/shares`, query),
    getListSharedForUser: (query) => list(`shared-investor-files`, query),
  },

  dashboard: {
    users: {
      get: (uuid = required('users uuid')) => get(`users/${uuid}`, {}),
      update: (uuid = required('user uuid'), data = {}) => patch(`users/${uuid}`, data),
      create: (data) => post(`users/invite`, data),
      list: (query) => list('dashboard/users', query),
      licenseesList: (query) =>
        list('dashboard/users', { roles: [USER_ROLE.PLATFORM_LICENSEE], ...query }),
      clientsList: (uuid, query) => list(`dashboard/users`, { 'advisor.uuid': uuid, ...query }),
      activityList: (query) => list('dashboard/user-activities', query),
      removeActivity: (uuid = required('activity uuid'), query) =>
        remove(`user-activities/${uuid}`, query),
      addAdvisor: (uuid = required('user uuid'), data) => post(`users/${uuid}/advisors`, data),
      removeAdvisor: (userUuid, advisorUuid) => remove(`users/${userUuid}/advisors/${advisorUuid}`),
      getAdvisors: (uuid = required('user uuid'), query) => list(`users/${uuid}/advisors`, query),
      dashboardEntitlements: (uuid = required('user uuid')) =>
        get(`users/${uuid}/dashboard-entitlements`),
      loginAs: (uuid = required('user uuid')) =>
        patch(`dashboard/users/${uuid}/impersonate`).then(({ auth, user }) => {
          storage.write(SESSION_EXPIRED_TIME_KEY)
          wcodeEnableSavingStorage.value = true
          authStorage.auth = auth
          userStorage.value = user
          return { auth, user }
        }),
    },
    investors: {
      //customPage and customLimit BE workaround
      // list: ({ page = 1, limit = 10, ...query }) =>
      //   list('investors', { 'pagination[page]': page, 'pagination[limit]': limit, ...query }),
      list: (query) => list('dashboard/investors', query),
      listByUser: (uuid, query) => list('dashboard/investors', { 'user.uuid': uuid, ...query }),
      get: (uuid = required('investor uuid')) =>
        get(`investors/${uuid}`, { 'groups[]': 'investor:get:no-mask' }),
      listBackgroundsChecksByInvestment: (investorUuid = required('investor uuid'), query) =>
        list('background-checks', { 'investor.uuid': investorUuid, ...query }),
      listDocuments: ({ investorUuid = required('investor uuid'), ...query }) =>
        list('dashboard/investor-files', {
          'investor.uuid': investorUuid,
          groups: ['offering:get', 'offering-updates:files:get'],
          ...query,
        }),
      runKYC: ({ investorUuid } = required('investor uuid')) =>
        post(`investors/${investorUuid}/kyc`),
      kycList: ({ investorUuid } = required('investor uuid'), query) =>
        list(`investors/${investorUuid}/kyc`, query),
      runTinCheck: (data) => post('tincheck/run', data),
      tinCheckListByInvestor: ({ investorUuid }, query) =>
        list('tincheck/request-logs', { ...query, 'investor.uuid': investorUuid }),
      generateInvestorsFiles: (query) => get('investors/download-files', query),
      investorsFilesList: (query) =>
        list('dashboard/reports', {
          reportType: ReportsInvestorFilesType,
          ...query,
        }),
    },
    investments: {
      get: (uuid = required('investment uuid')) =>
        get(`investments/${uuid}`, {
          groups: [
            'investor:get',
            'beneficiary:get',
            'investment-custodian:get',
            'offering:get',
            'investment-extended-signature:get',
          ],
          funding: true,
        }),
      update: (uuid = required('investment uuid'), data) => patch(`investments/${uuid}`, data),
      toggleIssuerSign: (uuid = required('investment uuid')) =>
        patch(`investments/${uuid}/toggle-offering-issuer-sign`, {}),
      removeSignatures: (uuid = required('investment uuid')) =>
        patch(`investments/${uuid}/clear-all-signatures`, {}),
      signExternalAgreement: (uuid = required('investment uuid')) =>
        patch(`investments/${uuid}/sign-extended-agreement`, {}),
      unsignExternalAgreement: (uuid = required('investment uuid')) =>
        patch(`investments/${uuid}/unsign-extended-agreement`, {}),
      list: (query) => list('dashboard/investments', { withFundingId: true, ...query }),
      listExtended: (query) =>
        list('dashboard/investments-extended', {
          withFundingId: true,
          withCustomSurvey: true,
          ...query,
        }).then(({ items, ...other }) => {
          const updatedItems = items.map((investment) => {
            if (!investment?.customSurvey) {
              return investment
            }
            return {
              ...investment,
              customSurvey: {
                ...investment?.customSurvey,
                questions: investment.customSurvey.questions
                  ?.map(initQuestion)
                  .sort(questionsSorter),
                answers: generateAnswers(
                  investment.customSurvey.questions,
                  investment.customSurvey.answers,
                ),
              },
            }
          })
          return { items: updatedItems, ...other }
        }),
      listByOffering: ({ offeringUuid, ...query }) =>
        list('dashboard/investments', { 'offering.uuid': offeringUuid, ...query }),
      getSA: (uuid = required('investment uuid')) =>
        get(`investments/${uuid}/subscription-agreement`),
      getSAFA: (uuid = required('investment uuid')) =>
        get(`investments/${uuid}/fundamerica/subscription-agreement`),
      listAmlExceptionByInvestment: (investmentUuid = required('Investment Uuid'), query) =>
        list('aml-exceptions', { 'investment.uuid': investmentUuid, ...query }),
      listBackgroundsChecksByInvestment: (investmentUuid = required('Investment Uuid'), query) =>
        list('background-checks', { 'amlException.investment.uuid': investmentUuid, ...query }),
      listByInvestor: (investorUuid, query) =>
        list(`dashboard/investments`, {
          'investor.uuid': investorUuid,
          groups: ['offering:get'],
          ...query,
        }),
      paymentsList: ({ investmentUuid } = required('investor uuid'), query) =>
        list(`investment-payments`, { 'investment.uuid': investmentUuid, ...query }),
      addAdvisor: (uuid = required('investment uuid'), data) =>
        post(`investments/${uuid}/advisors`, data),
      removeAdvisor: (advisorUuid = required('advisor uuid')) =>
        remove(`investment-advisors/${advisorUuid}`),
      getDistributionInstructions: (investmentUuid = required('investment uuid'), query) =>
        list(`investment-distribution-instructions`, {
          'investment.uuid': investmentUuid,
          ...query,
        }).then(({ items }) => items?.[0]),
      generateAgreements: (query) => get('investments/download-agreements', query),
      agreementsReportsList: (query) =>
        list('dashboard/reports', {
          reportType: ReportsDocumentSubscriptionAgreementType,
          ...query,
        }),
      getPHXA: (uuid = required('investment uuid')) =>
        get(`investments/${uuid}/phxa`).then((value) => initPHXA(value)),
      exportToPHXA: (uuid = required('investment uuid'), data) =>
        patch(`investments/${uuid}/phxa`, data),
      getExternalServiceQueue: (query) => list(`external-service-queue-items`, query),
    },
    investmentPayments: {
      listByInvestment: (investmentUuid, query) =>
        list('investment-payments', { 'investment.uuid': investmentUuid, ...query }),
    },
    paymentInformation: {
      get: (uuid = required('uuid')) => get(`payment-informations/${uuid}`),
      list: ({ investmentUuid } = required('investor uuid'), query) =>
        list(`payment-informations`, { 'investment.uuid': investmentUuid, ...query }),
      create: (data) => post('payment-informations', data),
      update: (uuid = required('exception uuid'), data) =>
        patch(`payment-informations/${uuid}`, data),
      remove: ({ uuid = required('payment uuid') }) => remove(`payment-informations/${uuid}`),
    },
    amlExceptions: {
      get: (uuid = required('exception uuid')) => get(`aml-exceptions/${uuid}`),
      list: (query) =>
        list('aml-exceptions', {
          ...query,
          groups: ['offering:get', 'offering-updates:files:get'],
        }),
    },
    backgroundChecks: {
      get: (uuid = required('background check uuid')) => get(`background-checks/${uuid}`),
      list: (query) => list('background-checks', query),
    },
    portals: {
      get: (uuid = required('portal uuid')) => get(`portals/${uuid}`),
      list: (query) => list('portals', query),
      domainsList: (uuid = required('portal uuid'), query) =>
        list(`portals/${uuid}/domains`, query),
      pages: ({ portalUuid, ...query }) =>
        list(`portal-pages`, { 'portal.uuid': portalUuid, ...query }),
      addOffering: (portalsUuid = required('portal uuid'), query) =>
        post(`portals/${portalsUuid}/offerings`, { weight: 0, ...query }),
      removeOffering: {
        remove: ({
          portalsUuid = required('portal uuid'),
          offeringUuid = required('offering uuid'),
        }) => remove(`portals/${portalsUuid}/offerings/${offeringUuid}`),
      },
      landingPageDetails: (uuid = required('landing page uuid')) => get(`portal-pages/${uuid}`),
    },
    offerings: {
      get: (uuid = required('offering uuid'), params) =>
        get(`offerings/${uuid}`, {
          ...params,
          groups: [
            'offering-issuer:get',
            // 'offering-security:get',
            // 'offering-information:get',
            // 'subscription-agreement-template:get',
            // 'offering-associated-person:get',
            ...(Array.isArray(params?.groups) ? params.groups : []),
          ],
        }),
      update: (uuid = required('offering uuid'), data) => patch(`offerings/${uuid}`, data),
      list: (query) => list('dashboard/offerings', { admin: true, ...query }),
    },

    offeringsUpdate: {
      documentsList: ({ offeringUuid, ...query }) =>
        list('dashboard/offering-updates/files', { 'offering.uuid': offeringUuid, ...query }),
      documentGet: (uuid = required('uuid')) => get(`offering-updates/files/${uuid}`),
      documentAdd: (data) => postFormData(`offering-updates/files`, data),
      documentEdit: (uuid, data) => patch(`offering-updates/files/${uuid}`, data),
      notifyDocuments: (uuid, data) => post(`offering-updates/notify/files/${uuid}`, data),

      postsList: ({ offeringUuid, ...query }) =>
        list('dashboard/offering-updates/posts', { 'offering.uuid': offeringUuid, ...query }),
      postGet: (uuid = required('uuid')) => get(`offering-updates/posts/${uuid}`),
      postAdd: (data) => post(`offering-updates/posts`, data),
      postEdit: (uuid, data) => patch(`offering-updates/posts/${uuid}`, data),

      notifyPosts: (uuid, data) => post(`offering-updates/notify/posts/${uuid}`, data),

      investorsAll: (query) =>
        list(`offering-updates-investors`, {
          ...query,
          limit: 999999,
        }),
    },

    offeringsCustomField: {
      list: (uuid = required('offering uuid'), query) =>
        list('offering-custom-fields', { 'offering.uuid': uuid, ...query }),
    },

    escrowDisbursement: {
      get: (uuid = required('escrow disbursements uuid')) =>
        get(`escrow-disbursements/${uuid}`, {}),
      update: (uuid = required('escrow disbursements uuid'), data) =>
        patch(`escrow-disbursements/${uuid}`, data),
      list: (query) => list('escrow-disbursements', query),
    },
    sectionAccessRequests: {
      get: (uuid = required('section request uuid')) => get(`access-requests/${uuid}`),
      update: (uuid = required('section request uuid'), data) =>
        patch(`access-requests/${uuid}`, data),
      list: (query) => list('dashboard/access-requests', query),
      remove: (uuid = required('section request uuid')) => remove(`access-requests/${uuid}`),
    },
    importDocumentsCheck: {
      getQueuesList: (query) => list('dashboard/investor-file-import-queues', query),
      getQueue: (uuid = required('queue uuid')) => get(`investor-file-import-queues/${uuid}`),
      getQueuesFilesList: (uuid = required('queue uuid'), query = {}) =>
        list(`investor-file-import-queues/${uuid}/files`, query),
      getQueuesFile: (uuid = required('file uuid')) =>
        get(`investor-file-import-queue-files/${uuid}`),
      updateFileStatusApproved: (uuid = required('file uuid'), investorUuid) =>
        patch(`investor-file-import-queue-files/${uuid}`, {
          status: 'approved',
          matchedInvestorUuid: investorUuid,
        }),
      updateFileStatusDenied: (uuid = required('file uuid'), investorUuid) =>
        patch(`investor-file-import-queue-files/${uuid}`, {
          status: 'declined',
          matchedInvestorUuid: investorUuid,
        }),
      updateFileNotes: (uuid = required('file uuid'), data) =>
        patch(`investor-file-import-queue-files/${uuid}`, data),
      importFilesToInvestors: (uuid = required('queue uuid')) =>
        patch(`investor-file-import-queues/${uuid}/import`),
      getNotesList: (uuid = required('file uuid'), query) =>
        list(`investor-file-import-queue-files/${uuid}/notes`, query),
      getNote: (uuid = required('note uuid')) =>
        get(`investor-file-import-queue-file-notes/${uuid}`),
      createNote: (uuid = required('file uuid'), data) =>
        post(`investor-file-import-queue-files/${uuid}/notes`, data),
      updateNote: (uuid = required('note uuid'), data) =>
        patch(`investor-file-import-queue-file-notes/${uuid}`, data),
      removeNote: (uuid = required('note uuid')) =>
        remove(`investor-file-import-queue-file-notes/${uuid}`),
      importFilesToInvestorsMatched: (uuid = required('queue uuid')) =>
        patch(`investor-file-import-queues/${uuid}/import-matched`),
      rollBackK1ByFile: (queueUuid = required('queue uuid'), fileUuid = required('file uuid')) =>
        patch(`investor-file-import-queues/${queueUuid}/rollback`, {
          queueFileUuids: [fileUuid],
        }),
      rollBackAggregateByInvestor: (
        queueUuid = required('queue uuid'),
        investorUuid = required('investor uuid'),
      ) =>
        patch(`investor-file-import-queues/${queueUuid}/rollback-aggregate-files`, {
          investorUuids: [investorUuid],
        }),
    },
    wcode: {
      list: (query) => list('wcodes/investors', query),
      listInvestors: (query) => list('wcodes/investors', query),
      listInvestorsByUser: (uuid = required('user uuid'), query) =>
        list('wcodes/investors', { ['investor.uuid']: uuid, ...query }),
      addInvestor: (data) => post('wcodes/investors', data),
      removeInvestor: (uuid = required('user uuid')) => remove(`wcodes/investors/${uuid}`),
      listInvestments: (query) => list('wcodes/investments', query),
      listInvestmentsByInvestment: (uuid = required('investment uuid'), query) =>
        list('wcodes/investments', { ['investment.uuid']: uuid, ...query }),
      addInvestment: (data) => post('wcodes/investments', data),
      removeInvestment: (uuid = required('user uuid')) => remove(`wcodes/investments/${uuid}`),
      listWholesalers: (query) => list('wcodes/wholesalers', query),
      listWholesalersByUser: (uuid = required('user uuid'), query) =>
        list('wcodes/wholesalers', { ['user.uuid']: uuid, ...query }),
      addWholesaler: (data) => post('wcodes/wholesalers', data),
      removeWholesaler: (uuid = required('user uuid')) => remove(`wcodes/wholesalers/${uuid}`),
      listLicenseeRestrictionsByUser: (uuid = required('user uuid'), query) =>
        list('wcodes/restrictions', { ['user.uuid']: uuid, ...query }),
      addLicenseeRestriction: (data) => post('wcodes/restrictions', data),
      removeLicenseeRestriction: (uuid = required('user uuid')) =>
        remove(`wcodes/restrictions/${uuid}`),
      listCrdLicenseeRestrictionsByUser: (uuid = required('user uuid'), query) =>
        list('crd/restrictions', { ['user.uuid']: uuid, ...query }),
      addCrdLicenseeRestriction: (data) => post('crd/restrictions', data),
      removeCrdLicenseeRestriction: (uuid = required('user uuid')) =>
        remove(`crd/restrictions/${uuid}`),
    },
    documentsShare: {
      listSharedForUser: (uuid, query) => list(`users/${uuid}/shared-investor-files`, query),
      removeShare: (uuid = required('activity uuid'), query) =>
        remove(`shared-investor-files/${uuid}`, query),
    },
    objectHistory: {
      users: {
        get: (uuid = required('user uuid'), query) => list(`object-history/user/${uuid}`, query),
      },
      wcodesInvestors: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/wcode_investor/${uuid}`, query),
      },
      wcodeWholeSaler: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/wcode_whole_saler/${uuid}`, query),
      },
      investment: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/investment/${uuid}`, query),
      },
      investor: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/investor/${uuid}`, query),
      },
      associatedPerson: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/associated_person/${uuid}`, query),
      },
      distributionInstructions: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/investment_distribution_instructions/${uuid}`, query),
      },
      investmentAdvisor: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/investment_advisor/${uuid}`, query),
      },
      paymentInformation: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/payment_information/${uuid}`, query),
      },
      offering: {
        getUnitClasses: (uuid = required('user uuid'), query) =>
          list(`object-history/offering/${uuid}`, { changedField: 'unitClasses', ...query }),
        getOfferingUpdates: (uuid = required('user uuid'), query) =>
          list(`object-history/offering/${uuid}`, { changedField: 'posts', ...query }),
        getOfferingDocuments: (uuid = required('user uuid'), query) =>
          list(`object-history/offering/${uuid}`, { changedField: 'files', ...query }),
      },
      unitClass: {
        get: (uuid = required('user uuid'), query) =>
          list(`object-history/unit_class/${uuid}`, query),
      },
      portal: {
        get: (uuid = required('portal uuid'), query) =>
          list(`object-history/portal/${uuid}`, query),
      },
      portalPage: {
        get: (uuid = required('page uuid'), query) =>
          list(`object-history/portal_page/${uuid}`, query),
      },
      custodian: {
        get: (uuid = required('custodian uuid'), query) =>
          list(`object-history/investment_custodian/${uuid}`, query),
      },
      investmentNotes: {
        get: (uuid = required('note uuid'), query) => list(`trade_review_notes/${uuid}`, query),
        getByInvestment: (uuid = required('investment uuid'), query) =>
          list(`object-history/investment/${uuid}`, {
            ...query,
            changedField: 'tradeReviewNotes',
            'withRelatedFields[tradeReviewNotes]': 'investment',
          }),
      },
      userNotes: {
        get: (uuid = required('note uuid'), query) => list(`user_notes/${uuid}`, query),
        getByUser: (uuid = required('client uuid'), query) =>
          list(`object-history/user/${uuid}`, {
            ...query,
            changedField: 'userNotes',
            'withRelatedFields[userNotes]': 'user',
          }),
      },
      csaByAdvisor: {
        get: (uuid = required('advisor uuid'), query) =>
          list(`object-history/user/${uuid}`, {
            ...query,
            changedField: 'csaAdvisors',
            'withRelatedFields[CSAToAdvisor]': 'csaAdvisors',
          }),
      },
    },
    emailHistory: {
      list: (query) => list('email-history', query),
      listNotificationTypes: (query) => list('notification-types', query),
      resendEmail: (uuid = required('uuid')) => patch(`email-history/${uuid}/resend`),
    },
    ippForm: {
      list: (query) => list('ipp-agreements', query),
      get: (uuid = required('ipp form uuid')) => get(`ipp-agreements/${uuid}`),
      create: (data) => post('ipp-agreements', data),
      update: (uuid = required('ipp form uuid'), data) => patch(`ipp-agreements/${uuid}`, data),
    },
  },

  pdfFromHtml: {
    batch: ({ content = required('data'), name = required('name') }) =>
      post('pdf-from-html/batch', { content, name }),
    generate: (name = required('name'), data = {}) =>
      post('pdf-from-html/generate', { ...data, name }),
  },

  themes: {
    list: (query) => list('portal-themes', query),
  },
}

function bind(f, arg) {
  return (...args) => f(arg, ...args)
}

function required(name) {
  throw { error: `Missing ${name}` }
}

const MEMBER_VOTE_AND_PARTNER_VOTE = ['member vote', 'partner vote']

function initQuestion(question, isInvestorOwner) {
  return {
    ...question,
    //! TODO: fix this workaround (add )

    ...(MEMBER_VOTE_AND_PARTNER_VOTE.includes(question?.label?.toLowerCase()) &&
      !isInvestorOwner && { hideMemberVote: true }),
  }
}

function questionsSorter(a, b) {
  return a.order - b.order
}

function prepareFromBackConfirmationAttemptAttributes(data) {
  const formattedAttributes = data.attributes.reduce(
    (acc, item) => ({
      attributes: { ...acc.attributes, [item.name]: item },
      attributesValues: { ...acc.attributesValues, [item.name]: parseJson(item.value) },
    }),
    { attributes: {}, attributesValues: {} },
  )
  return { ...data, ...formattedAttributes }
}

function parseJson(value) {
  if (Number(value)) {
    return Number(value)
  }
  return value === 'true' || value === 'false' || value === 'null' ? JSON.parse(value) : value
}

function prepareDataForConfirmationAttemptAttributes(data) {
  return { data: Object.entries(data).map(([name, value]) => ({ name, value: String(value) })) }
}

function convertToFileWithAttributes({ file, ...data }) {
  const attributes = Object.entries(data).map(([name, value]) => ({ name, value }))
  return {
    file,
    ...(attributes.length && { attributes: JSON.stringify(attributes) }),
  }
}

function camelCaseToWords(s) {
  return s
    .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
    .replace(/[_]/g, ' ') // Replace underscores with spaces
    .replace(/\[(\d+)\]/g, '($1)') // Replace [number] with (number)
    .replace(/(\s*\(\d+\))/g, '$1') // Ensure no space before numbers in parentheses
    .replace(/(\d+)/g, ' $1') // Add space before any number
    .replace(/\./g, ' ') // Replace dots with spaces
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim() // Trim leading/trailing spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/\(\s*(\d+)\s*\)/g, '($1)') // Replace ( number ) or ( number) with (number)
}

function roundToScale(num, scale) {
  const factor = Math.pow(10, scale)
  return Math.round(num * factor) / factor
}

function parseProperty(name, property) {
  switch (property.type) {
    case 'date':
      return {
        ...property,
        type: QUESTION_TYPE.DATE,
        name: name,
        uuid: name,
        disabled: !property.editable,
        label: `${camelCaseToWords(name)} (${property.description ?? ''})`,
      }
    case 'integer':
    case 'float':
      return {
        ...property,
        type: QUESTION_TYPE.NUMBER,
        name: name,
        uuid: name,
        disabled: !property.editable,
        label: `${camelCaseToWords(name)} (${property.description ?? ''})`,
        step: property.scale ? 1 / Math.pow(10, property.scale) : undefined,
      }
    case 'enum':
      return {
        ...property,
        type: QUESTION_TYPE.SELECT,
        name: name,
        uuid: name,
        disabled: !property.editable,
        label: `${camelCaseToWords(name)} (${property.description ?? ''})`,
      }
    default:
      return {
        ...property,
        type: QUESTION_TYPE.TEXTAREA,
        name: name,
        uuid: name,
        disabled: !property.editable,
        label: `${camelCaseToWords(name)} (${property.description ?? ''})`,
        maxlength: property.length,
      }
  }
}

function initPHXA(array) {
  const result = array.reduce(
    (acc, item, i) => {
      const itemName = item.name
      const itemNameWithValue = item.name + 'withValue'
      const itemNameNoValue = item.name + 'noValue'
      const answers = { [itemNameWithValue]: 1 }
      const itemHeaderWithValue = {
        name: itemNameWithValue,
        uuid: itemNameWithValue,
        content: `## ${camelCaseToWords(itemName)}`,
        type: QUESTION_TYPE.STATIC_CONTENT,
        // 500 is chosen because each object group (PurchaseOrder, RelatedPartyN) has definitely less than 500 fields
        uiWeight: 500 * (i + 1),
      }
      const itemHeaderNoValue = {
        name: itemNameNoValue,
        uuid: itemNameNoValue,
        content: `## ${camelCaseToWords(itemName)}`,
        type: QUESTION_TYPE.STATIC_CONTENT,
        // 5000 is chosen because all object groups (PurchaseOrder, RelatedPartyN) in total have definitely less than 5000 fields
        uiWeight: 5000 * (i + 1),
      }
      const properties = Object.entries(item?.properties).map(([name, property]) => {
        const nameWithPrefix = itemName + '_' + name
        // answers[nameWithPrefix] = ['integer', 'float'].includes(property?.type)
        //   ? roundToScale(property.value, property.scale)
        //   : property?.value
        answers[nameWithPrefix] = property?.value
        const multiplier = property?.value ? 500 : 5000
        return {
          ...parseProperty(nameWithPrefix, property),
          uiWeight: multiplier * (i + 1) + property.weight,
        }
      })
      const headers = Object.values(answers)
        ? [itemHeaderWithValue, itemHeaderNoValue]
        : [itemHeaderNoValue]
      return {
        questions: [...acc.questions, ...headers, ...properties],
        answers: { ...acc.answers, ...answers },
      }
    },
    { questions: [], answers: {} },
  )

  result.questions = result.questions.sort((question1, question2) => {
    return Math.sign(question1.uiWeight - question2.uiWeight)
  })
  return result
}
